import eric from '../pictures/eric.png'
import zygi from '../pictures/blanco.JPG'
import jah from '../pictures/shadow.jpg'

export const artist = [{
    name: "t-bear",
    img: eric,
    sc: "https://w.soundcloud.com/player/?url=https%3A//soundcloud.com/t-bear",
    text: [<span style={{fontWeight: 'bold', fontSize: '30px'}}>Tranquil Bear</span>, ", a talented music producer from Upper Marlboro, Maryland, who has been honing his craft for the past three years. He produces various genres of music, such as hip-hop, R&B, and pop, and showcases his work on different platforms, such as SoundCloud and TikTok. He is confident in his abilities and has a unique and creative style that sets him apart from other producers."],
    insta: "https://www.instagram.com/prodtbear/",
    x: null,
    youtube: "https://www.youtube.com/@tranquilbear3152",
    tiktok: "https://www.tiktok.com/@tranquilbear1",
    threads: "https://www.threads.net/@prodtbear?xmt=AQGzA-porUru8YbKmIavs95QNL_aGAQohI66wcxNZgfATjU"
}, {
    name: "ZYGI",
    img: zygi,
    sc: "https://w.soundcloud.com/player/?url=https%3A//soundcloud.com/zyygi",
    text: ["Making that music for fun, ", <span style={{fontWeight: 'bold', fontSize: '30px'}}>ZYGI</span>, ", never thought of producing music for a living. As the year of 2021 was about to end, his best friend T-Bear started making beats and it quickly grew on him. Starting out with EDM and eventually falling in love with trap, atlanta rap, and other forms of hip-hop, he started making heat all from the footsteps of T-Bear."],
    insta: "https://www.instagram.com/ggp_zygi/",
    x: "https://twitter.com/ChonchoBlanco",
    youtube: "https://www.youtube.com/@zygizygli2819",
    tiktok: null,
    threads: null
}, {
    name: "shadow",
    img: jah,
    sc: "https://w.soundcloud.com/player/?url=https%3A//soundcloud.com/user-799254712",
    text: [<span style={{fontWeight: 'bold', fontSize: '30px'}}>Shadow</span>, ", a producer and an artist who comes from Kingston, Jamaica. Started producing 3 years ago, recently started to let his vocals shine on the mic. Growing up listening to trap, hip-hop, reggae, and dancehall, has created a passion for music. Having a good ear and assisting in the production process of T-Bear and ZYGI, he is ready to make waves in the scene."],
    insta: "https://www.instagram.com/jahvanietaylor/",
    x: null,
    youtube: null,
    tiktok: null,
    threads: null
}];