import React, { useRef } from 'react'
import Reveal from './Reveal'

import insta from '../pictures/Instagram_icon.png'
import x from '../pictures/X_icon.svg'
import youtube from '../pictures/YouTube_icon.svg'
import tiktok from '../pictures/tiktok_icon.png'
import threads from '../pictures/threads_icon.png'

import '../css/InfoContainer.css'

function InfoContainer(props) {
    const ref = useRef(0);

    return (
        <div id="info-container">
            {/* PICTURE DIVISION */}
            <div id="pic-div">
                {/* PICTURE TITLE */}
                <Reveal clickedArrow={props.clickedArrow} index={props.index}>
                    <h2 id="pic-title">{props.name}</h2>
                </Reveal>
                {/* PICTURE */}
                <Reveal clickedArrow={props.clickedArrow} index={props.index}>
                    <img src={props.img} alt={props.name} id="pic" />
                </Reveal>
                {/* ICONS */}
                <Reveal clickedArrow={props.clickedArrow} index={props.index}>
                    <ul>
                        <li>
                            {
                                props.insta?<a href={props.insta} target="_blank" rel="noopener noreferrer" className="iconLinks">
                                <img src={insta} className="icons" alt="instagram"/>
                                </a>:null
                            }
                            {
                                props.x?<a href={props.x} target="_blank" rel="noopener noreferrer" className="iconLinks">
                                <img src={x} className="icons" alt="x"/>
                                </a>:null
                            }
                            {
                                props.youtube?<a href={props.youtube} target="_blank" rel="noopener noreferrer" className="iconLinks">
                                <img src={youtube} className="icons" alt="youtube"/>
                                </a>:null
                            }
                            {
                                props.tiktok?<a href={props.tiktok} target="_blank" rel="noopener noreferrer" className="iconLinks">
                                <img src={tiktok} className="icons" alt="tiktok"/>
                                </a>:null
                            }
                            {
                                props.threads?<a href={props.threads} target="_blank" rel="noopener noreferrer" className="iconLinks">
                                <img src={threads} className="icons" alt="threads"/>
                                </a>:null
                            }
                        </li>
                    </ul>
                </Reveal>
            </div>
            {/* TEXT DIVISION */}
            <div id="text-div">
                <Reveal clickedArrow={props.clickedArrow} index={props.index}>
                    <p id="artist-bio">{props.text}</p>
                </Reveal>
            </div>
            {/* SOUNDCLOUD DIVISION */}
            <div id="sc-div">
                <Reveal clickedArrow={props.clickedArrow} index={props.index}>
                    <iframe id="sc-widget" src={props.sc} />
                </Reveal>
            </div>
        </div>
    );
}
 
export default InfoContainer;